import React, { FC, useEffect } from 'react'
import useForm from '@hooks/useForm'
import {
  FormAddSchedule,
  initialValues,
  initialValidation,
  TFormAddSchedule
} from '@components/Form/FormAddSchedule'
import GenericFormPage from '@templates/Generic/Form'
import { Button } from 'gatsby-material-ui-components'
import {
  getSchedule,
  pauseSchedule,
  TScheduleResponse,
  updateSchedule
} from '@utils/api'
import useApi from '@hooks/useApi'
import { PageProps } from 'gatsby'
import { ButtonGroup, Grid } from '@mui/material'
import { Pause, PlayArrow } from '@mui/icons-material'
import { KeyValueTable, Card } from '@components'

const ScanSchedulerAddPage: FC<PageProps> = ({ params }) => {
  const api = useApi<TScheduleResponse>({
    apiMethod: getSchedule,
    params: params.id
  })

  const saveApi = useApi({
    apiMethod: updateSchedule,
    requestOnMount: false
  })

  const pauseApi = useApi({ apiMethod: pauseSchedule, requestOnMount: false })

  const { response, makeRequest: refreshData } = api

  const form = useForm<TFormAddSchedule>({
    initialValues: {
      ...initialValues,
      name: params.id
    },
    initialValidationRules: initialValidation
  })

  const isPaused = response?.paused || false

  const handlePause = () => {
    pauseApi.makeRequest({ id: params.id, pause: !isPaused }).then(() => {
      pauseApi.enqueueSnackbar(`Schedule ${isPaused ? 'Resumed' : 'Paused'}`, {
        variant: 'success'
      })
      refreshData(params.id)
    })
  }

  useEffect(() => {
    if (response) {
      form.setValues({
        ...initialValues,
        ...response
      })

      console.log('form.values', form.values)
    }
  }, [response])

  return (
    <GenericFormPage
      title={params.id}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button
            disabled={pauseApi.loading || !response}
            endIcon={isPaused ? <PlayArrow /> : <Pause />}
            onClick={handlePause}
          >
            {isPaused ? 'Resume Schedule' : 'Pause Schedule'}
          </Button>
        </ButtonGroup>
      }
      awaitingData={api.loading}
      maxWidth="md"
      api={saveApi}
      breadcrumbs={[
        {
          title: 'Scheduler',
          to: '/scheduler/'
        },
        {
          title: `Edit Schedule`
        }
      ]}
      asideContent={
        <Card sx={{ height: 'auto' }}>
          <KeyValueTable
            variant="label-over"
            loading={api.loading}
            dataPoints={[
              ['Is Paused?', `${api.response?.paused}`],
              ['First Run', api.response?.date_first_run || '- no data -'],
              ['Last Run', api.response?.date_last_run || '- no data -'],
              ['Next Run', api.response?.date_next_run || '- no data -'],
              ['Run Count', api.response?.number_of_runs || 0]
            ]}
          />
        </Card>
      }
      form={form as any}
      successMessage="Schedule successfully updated."
      returnPath="/scheduler/"
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading || saveApi.loading}
            >
              Update Schedule
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormAddSchedule form={form as any} />
    </GenericFormPage>
  )
}

export default ScanSchedulerAddPage
